<template>
  <div class="work">
    <article class="row">
      <div class="column">
        <h1 class="headline">Datenschutzerklärung</h1>

        <p>Datum des Inkrafttretens: December 20, 2018</p>

        <p>Filip Basara ("wir", "uns", "unser" usw.) betreibt die Website www.filipbasara.net (nachstehend als "Dienst" bezeichnet).</p>

        <p>
          Diese Seite enthält Informationen zu der Art und Weise, auf welche wir personenbezogene Daten erfassen, nutzen und offenlegen, wenn Sie unseren Dienst nutzen, sowie zu den Optionen, die Ihnen im Zusammenhang mit diesen Daten zur Verfügung stehen.
          <a
            href="https://privacypolicies.com/blog/privacy-policy-template/"
          >Credits: Privacy Policies: Vorlage für die Datenschutzrichtlinie</a>.
        </p>

        <p>Wir nutzen Ihre Daten zur Bereitstellung und Verbesserung unseres Dienstes. Durch Inanspruchnahme des Dienstes erklären Sie sich mit der Erfassung und Nutzung von Daten durch uns nach Maßgabe dieser Richtlinie einverstanden. Soweit in dieser Datenschutz-Richtlinie nicht jeweils etwas anderes angegeben ist, kommt den in dieser Datenschutz-Richtlinie vorkommenden Begriffen jeweils dieselbe Bedeutung zu, die diesen in unseren Allgemeinen Geschäftsbedingungen (Terms and Conditions) (abrufbar über die www.filipbasara.net) zugewiesen wurde.</p>

        <h2>Begriffsbestimmungen</h2>
        <ul>
          <li>
            <p>
              <strong>Dienst</strong>
            </p>
            <p>Der Dienst ist die von dem Filip Basara betriebene Website www.filipbasara.net</p>
          </li>
          <li>
            <p>
              <strong>Personenbezogene Daten</strong>
            </p>
            <p>Personenbezogene Daten sind Daten, die sich auf eine lebende Person beziehen, welche anhand dieser Daten (bzw. anhand dieser Daten in Kombination mit weiteren Informationen, die sich bereits in unserem Besitz befinden oder mit Wahrscheinlichkeit in unseren Besitz gelangen werden) identifizierbar ist.</p>
          </li>
          <li>
            <p>
              <strong>Nutzungsdaten</strong>
            </p>
            <p>Nutzungsdaten sind Daten, die automatisch im Rahmen der Nutzung des Dienstes oder innerhalb der Dienstinfrastruktur selbst (beispielsweise für die Dauer eines Seitenbesuchs) erfasst werden.</p>
          </li>
          <li>
            <p>
              <strong>Cookies</strong>
            </p>
            <p>Cookies sind kleine Dateien, die auf Ihrem Gerät (Computer oder mobiles Endgerät) gespeichert werden.</p>
          </li>
        </ul>

        <h2>Erfassung und Nutzung von Daten</h2>
        <p>Wir erfassen verschiedene Arten von Daten für eine Reihe von Zwecken, um den Dienst, den wir Ihnen zur Verfügung stellen, zu verbessern.</p>

        <h3>Arten der erfassten Daten</h3>

        <h4>Personenbezogene Daten</h4>
        <p>Im Rahmen der Nutzung unseres Dienstes bitten wir Sie gegebenenfalls um die Zurverfügungstellung bestimmter persönlich identifizierbarer Daten, die wir dazu nutzen, um Sie zu kontaktieren oder zu identifizieren ("personenbezogene Daten"). Persönlich identifizierbare Daten umfassen beispielsweise folgende Daten (sind jedoch nicht auf diese beschränkt):</p>

        <ul>
          <li>Cookies und Nutzungsdaten</li>
        </ul>

        <h4>Nutzungsdaten</h4>

        <p>Wir können außerdem Daten zu der Art und Weise erfassen, auf welche auf unseren Dienst zugegriffen wird bzw. auf welche diese genutzt werden ("Nutzungsdaten"). Diese Nutzungsdaten umfassen gegebenenfalls die Internet-Protocol-Adresse (IP-Adresse) Ihres Computers, Ihren Browsertyp, Ihre Browserversion, die von Ihnen innerhalb unseres Dienstes besuchten Seiten, den Zeitpunkt und das Datum Ihres Besuchs, die Gesamtverweildauer auf den betreffenden Seiten, individuelle Geräteidentifikationsmerkmale und weitere Diagnostikdaten.</p>

        <h4>Tracking & Cookies</h4>
        <p>Wir setzen Cookies und ähnliche Tracking-Technologien zur Überwachung der Aktivität innerhalb unseres Dienstes ein und speichern in diesem Zusammenhang bestimmte Daten.</p>
        <p>Cookies sind Dateien mit einem geringen Datenumfang, wie zum Beispiel anonyme einzigartige Identifikatoren. Cookies werden von einer Website an Ihren Browser gesendet und auf Ihrem Gerät gespeichert. Die sonstigen von uns eingesetzten Tracking-Technologien sind so genannte Beacons, Tags und Scripts und dienen der Erfassung und Nachverfolgung von Daten sowie der Verbesserung und Analyse unseres Dienstes.</p>
        <p>
          Sie können in den Einstellungen Ihres Browsers bestimmen, ob Sie alle Cookies ablehnen oder nur bestimmte Cookies akzeptieren möchten. Falls Sie jedoch die Annahme von Cookies verweigern, können Sie gegebenenfalls Teile unseres Dienstes nicht in Anspruch nehmen. Weitere Informationen zum Verwalten von Cookies finden Sie im
          <a
            href="https://privacypolicies.com/blog/how-to-delete-cookies/"
          >Browser-Cookies-Handbuch</a>.
        </p>
        <p>Beispiele für von uns eingesetzte Cookies:</p>
        <ul>
          <li>
            <strong>Sitzungs-Cookies.</strong> Wir setzen Sitzungs-Cookies für den Betrieb unseres Dienstes ein.
          </li>
          <li>
            <strong>Präferenz-Cookies.</strong> Wir setzen Präferenz-Cookies ein, um Ihre Präferenzen und verschiedenen Einstellungen zu speichern.
          </li>
          <li>
            <strong>Sicherheits-Cookies.</strong> Wir setzen Sicherheits-Cookies für Sicherheitszwecke ein.
          </li>
        </ul>

        <h2>Datennutzung</h2>
        <p>Wir bei Filip Basara nutzen die erfassten Daten für verschiedene Zwecke, beispielsweise um:</p>
        <ul>
          <li>Ihnen unseren Dienst zur Verfügung zu stellen und diesen aufrecht zu erhalten;</li>
          <li>Ihnen Änderungen in Bezug auf unseren Dienst mitzuteilen;</li>
          <li>es Ihnen auf Wunsch zu ermöglichen, an den interaktiven Teilen unseres Dienstes teilzunehmen;</li>
          <li>Kundendienstleistungen zur Verfügung zu stellen;</li>
          <li>Analysedaten und sonstige wertvolle Daten zu erfassen, damit wir unseren Dienst verbessern können;</li>
          <li>die Nutzung unseres Dienstes zu überwachen;</li>
          <li>technische Probleme zu erkennen, zu vermeiden und zu beheben;</li>
        </ul>

        <h2>Übertragung von Daten</h2>
        <p>Ihre Daten, einschließlich personenbezogener Daten, können auf Computer übertragen – und auf solchen aufbewahrt – werden, die sich außerhalb Ihres Heimatstaates, Ihrer Heimatprovinz, Ihres Heimatlandes oder einer sonstigen Rechtsordnung befinden und somit Datenschutzgesetzen unterliegen, die sich von den Datenschutzgesetzen in Ihrer Rechtsordnung unterscheiden.</p>
        <p>Falls Sie sich außerhalb von Germany befinden und sich dazu entscheiden, Daten an uns zu übermitteln, müssen Sie zur Kenntnis nehmen, dass wir Ihre Daten, einschließlich personenbezogener Daten, nach Germany übertragen und diese dort verarbeiten.</p>
        <p>Ihre Zustimmung zu dieser Datenschutz-Richtlinie und eine nachfolgende Übermittlung von Daten Ihrerseits stellt eine Einverständniserklärung Ihrerseits zu der genannten Übertragung dar.</p>
        <p>Filip Basara wird alle im zumutbaren Rahmen erforderlichen Schritte unternehmen um sicherzustellen, dass Ihre Daten auf sichere Weise sowie in Übereinstimmung mit dieser Datenschutz-Richtlinie behandelt werden, und dass Ihre personenbezogenen Daten nicht an Organisationen oder in Länder übertragen werden, hinsichtlich welcher keine hinreichenden Kontrollmechanismen in Bezug auf die Sicherheit Ihrer Daten und sonstigen personenbezogenen Informationen vorliegen.</p>

        <h2>Offenlegung von Daten</h2>

        <h3>Gesetzliche Anforderungen</h3>
        <p>Filip Basara kann Ihre personenbezogenen Daten unter Umständen offenlegen, wenn es unter Beachtung der Grundsätze von Treu und Glauben der Ansicht ist, dass dies zur Erreichung der nachfolgenden Zielsetzungen erforderlich ist:</p>
        <ul>
          <li>zur Erfüllung einer gesetzlichen Pflicht</li>
          <li>zum Schutz und zur Verteidigung der Rechte oder des Eigentums von Filip Basara</li>
          <li>zur Vermeidung oder Untersuchung möglicher Fehlverhaltensweisen in Bezug auf den Dienst</li>
          <li>zum Schutz der persönlichen Sicherheit der Nutzer des Dienstes oder der Öffentlichkeit</li>
          <li>zur Vermeidung von Haftungsansprüchen</li>
        </ul>

        <h2>Datensicherheit</h2>
        <p>Die Sicherheit Ihrer Daten ist uns wichtig. Bitte vergessen Sie jedoch nicht, dass es keine Übertragungsmethoden über das Internet und keine elektronischen Speichermedien gibt, die 100 % sicher sind. Obwohl wir stets bemüht sind, kommerziell annehmbare Maßnahmen zum Schutz Ihrer personenbezogenen Daten umzusetzen, können wir eine absolute Sicherheit nicht garantieren.</p>

        <h2>Leistungsanbieter</h2>
        <p>Wir beauftragen gegebenenfalls dritte Unternehmen und Einzelpersonen ("Leistungsanbieter") mit Unterstützungsleistungen zum einfacheren Angebot unseres Dienstes, mit der Erbringung von Leistungen in unserem Namen, mit der Erbringung von mit unserem Dienst verbundenen Leistungen oder mit Unterstützungsleistungen zur Analyse der Art und Weise, auf die unser Dienst in Anspruch genommen wird.</p>
        <p>Diese Dritten können auf Ihre personenbezogenen Daten nur in dem Umfang Zugriff nehmen, der für die Erfüllung der genannten Aufgaben in unserem Namen erforderlich ist, und dürfen diese für keine sonstigen Zwecke offenlegen oder nutzen.</p>

        <h3>Analytik</h3>
        <p>Wir beauftragen gegebenenfalls dritte Leistungsanbieter mit der Überwachung und Analyse der Nutzung unseres Dienstes.</p>
        <ul>
          <li>
            <p>
              <strong>Google Analytics</strong>
            </p>
            <p>Google Analytics ist ein von Google angebotener Web-Analytics-Dienst, der Zugriffe auf Websites nachverfolgt und meldet. Google nutzt die gewonnenen Daten zur Nachverfolgung und Überwachung der Nutzung unseres Dienstes. Diese Daten werden mit anderen Google-Diensten geteilt. Google kann die gewonnenen Daten zur Kontextualisierung und Personalisierung der Werbeanzeigen innerhalb seines eigenen Werbenetzwerks nutzen.</p>
            <p>Sie können die Übertragung Ihrer Aktivität innerhalb unseres Dienstes an Google Analytics abschalten, indem Sie das Browser-Add-on zur Deaktivierung von Google Analytics installieren. Das Add-on verhindert eine Datenübertragung an Google Analytics zu Besuchen bzw. Aktivität über das JavaScript von Google Analytics (ga.js, analytics.js und dc.js).</p>
            <p>
              Weitere Informationen zu den Datenschutzmaßnahmen von Google können Sie auf Googles Webseite zu seinen Datenschutzbestimmungen (Privacy Terms) einsehen:
              <a
                href="https://policies.google.com/privacy?hl=en"
              >https://policies.google.com/privacy?hl=en</a>
            </p>
          </li>
        </ul>

        <h2>Links zu anderen Websites</h2>
        <p>Unser Dienst kann Links zu anderen Websites enthalten, die nicht von uns betrieben werden. Wenn Sie auf einen Drittlink klicken, werden Sie direkt auf die Website des betreffenden Dritten weitergeleitet. Wir empfehlen Ihnen dringend, sich jeweils die Datenschutz-Richtlinien aller von Ihnen besuchten Websites durchzulesen.</p>
        <p>Wir haben keine Kontrolle über die Inhalte, Datenschutzvorschriften und -praktiken dritter Websites oder Dienste und übernehmen in diesem Zusammenhang keine Haftung.</p>

        <h2>Privatsphäre Minderjähriger</h2>
        <p>Unser Dienst richtet sich nicht an Personen, die das 18. Lebensjahr noch nicht vollendet haben ("minderjährige Personen").</p>
        <p>Wir erfassen wissentlich keine persönlich identifizierbaren Daten zu minderjährigen Personen. Falls Sie ein Elternteil oder Vormund sind und es Ihnen bekannt wird, dass eine Ihrer Aufsicht unterstehende minderjährige Person uns personenbezogene Daten übermittelt hat, bitten wir Sie, mit uns Kontakt aufzunehmen. Falls uns bekannt wird, dass wir personenbezogene Daten einer minderjährigen Person ohne elterliche Zustimmung erfasst haben, setzen wir Maßnahmen zur Entfernung dieser Daten von unseren Servern um.</p>

        <h2>Änderungen dieser Datenschutz-Richtlinie</h2>
        <p>Wir können unsere Datenschutz-Richtlinie von Zeit zu Zeit aktualisieren. Jegliche solcher Änderungen teilen wir Ihnen mit, indem wir die aktualisierte Fassung auf dieser Seite veröffentlichen.</p>
        <p>Wir werden Sie vor dem Inkrafttreten der betreffenden Änderung per E-Mail und/oder mittels einer sonstigen sichtbaren Mitteilung innerhalb unseres Dienstes informieren und das "Datum des Inkrafttretens" am Beginn dieser Datenschutz-Richtlinie aktualisieren.</p>
        <p>Wir empfehlen Ihnen, diese Datenschutz-Richtlinie regelmäßig auf Änderungen hin durchzusehen. Änderungen dieser Datenschutz-Richtlinie werden im Zeitpunkt ihrer Veröffentlichung auf dieser Seite wirksam.</p>

        <h2>Kontaktaufnahme</h2>
        <p>Falls Sie Fragen zu dieser Datenschutz-Richtlinie haben, können Sie wie folgt Kontakt zu uns aufnehmen:</p>
        <ul>
          <li>Per E-Mail: contact@filipbasara.net</li>
        </ul>
      </div>
    </article>
    <article class="row">
      <div class="column">
        <h1 class="headline">Privacy Policy</h1>

        <p>Effective date: December 20, 2018</p>

        <p>Filip Basara ("us", "we", or "our") operates the www.filipbasara.net website (hereinafter referred to as the "Service").</p>

        <p>
          This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. Our Privacy Policy for Filip Basara is based on the
          <a
            href="https://privacypolicies.com/blog/privacy-policy-template/"
          >Privacy Policy Template from Privacy Policies</a>.
        </p>

        <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from www.filipbasara.net</p>

        <h2>Information Collection And Use</h2>

        <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

        <h3>Types of Data Collected</h3>

        <h4>Personal Data</h4>

        <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>

        <ul>
          <li>Cookies and Usage Data</li>
        </ul>

        <h4>Usage Data</h4>

        <p>We may also collect information on how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>

        <h4>Tracking & Cookies Data</h4>
        <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
        <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
        <p>
          You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service. You can learn more how to manage cookies in the
          <a
            href="https://privacypolicies.com/blog/how-to-delete-cookies/"
          >Browser Cookies Guide</a>.
        </p>
        <p>Examples of Cookies we use:</p>
        <ul>
          <li>
            <strong>Session Cookies.</strong> We use Session Cookies to operate our Service.
          </li>
          <li>
            <strong>Preference Cookies.</strong> We use Preference Cookies to remember your preferences and various settings.
          </li>
          <li>
            <strong>Security Cookies.</strong> We use Security Cookies for security purposes.
          </li>
        </ul>

        <h2>Use of Data</h2>

        <p>Filip Basara uses the collected data for various purposes:</p>
        <ul>
          <li>To provide and maintain the Service</li>
          <li>To notify you about changes to our Service</li>
          <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
          <li>To provide customer care and support</li>
          <li>To provide analysis or valuable information so that we can improve the Service</li>
          <li>To monitor the usage of the Service</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>

        <h2>Transfer Of Data</h2>
        <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
        <p>If you are located outside Germany and choose to provide information to us, please note that we transfer the data, including Personal Data, to Germany and process it there.</p>
        <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
        <p>Filip Basara will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>

        <h2>Disclosure Of Data</h2>

        <h3>Legal Requirements</h3>
        <p>Filip Basara may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
        <ul>
          <li>To comply with a legal obligation</li>
          <li>To protect and defend the rights or property of Filip Basara</li>
          <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
          <li>To protect the personal safety of users of the Service or the public</li>
          <li>To protect against legal liability</li>
        </ul>

        <h2>Security Of Data</h2>
        <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

        <h2>Service Providers</h2>
        <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
        <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

        <h3>Analytics</h3>
        <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
        <ul>
          <li>
            <p>
              <strong>Google Analytics</strong>
            </p>
            <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.</p>
            <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.</p>
            <p>
              For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page:
              <a
                href="https://policies.google.com/privacy?hl=en"
              >https://policies.google.com/privacy?hl=en</a>
            </p>
          </li>
        </ul>

        <h2>Links To Other Sites</h2>
        <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

        <h2>Children's Privacy</h2>
        <p>Our Service does not address anyone under the age of 18 ("Children").</p>
        <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>

        <h2>Changes To This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us:</p>
        <ul>
          <li>By email: contact@filipbasara.net</li>
        </ul>
      </div>
    </article>
  </div>
</template>
 
<script>
// @ is an alias to /src

export default {
  name: "work",
  components: {}
};
</script>
 